<script>
import api from "../api";
import example4 from "../assets/tuning-fork-1902632_640.jpg";
// import RedeemItem from "../components/RedeemItem.vue";
import ShippingAddress from "../components/Address.vue";
// import Loader from "vue-element-loading";
import CartItem from "../components/CartItem";
import Message from "ant-design-vue/lib/message";
import AEmpty from "ant-design-vue/lib/empty";
export default {
  components: {
    CartItem,

    // RedeemItem,
    ShippingAddress,
    AEmpty,

    // Loader,
  },
  data() {
    return {
      value: 1,
      img4: example4,
      address: null,
      loading: false,
    };
  },
  computed: {
    cart() {
      return this.$store.state.Cart.cart;
    },
    cartCount() {
      return this.$store.getters["Cart/cartCount"];
    },
    cartPoints() {
      return this.$store.getters["Cart/cartTotalPoints"];
    },
  },
  methods: {
    addressChange(val) {
      this.address = val;
    },
    cartIsEmpty() {
      if (this.cartCount > 0) {
        return false;
      } else {
        return true;
      }
    },
    loadAddressDetails() {
      const lastShippingAddress = this.$store.state.Auth.lastShippingAddress;

      const translator = {
        firstName: "first_name",
        lastName: "last_name",
        addressLine1: "address_line_1",
        addressLine2: "address_line_2",
        addressLine3: "address_line_3",
        phone: "phone",
        city: "city",
        stateOrProvince: "state_or_province",
        postalCode: "postal_code",
        country: "country",
      };

      if (lastShippingAddress) {
        for (const field in this.address) {
          const newField = translator[field];
          const value = lastShippingAddress[newField];
          this.address[field] = value;
        }
      }
    },
    checkout() {
      const status = 1;
      this.loading = true;
      if (this.cart.length === 0) {
        Message.info(
          "Sorry, no items your cart. Why don't we go back and change that! "
        );
        this.loading = false;
        return;
      }

      const requiredFields = {
        "First Name": this.address.firstName,
        "Last Name": this.address.lastName,
        City: this.address.city,
        "Postal Code": this.address.postalCode,
        Country: this.address.country,
      };

      for (const name in requiredFields) {
        const value = requiredFields[name];
        if (value == "" || value == null) {
          Message.info(`No ${name}, we'd like to know that please!`);
          this.loading = false;
          return;
        }
      }

      /* eslint-disable @typescript-eslint/camelcase */
      const addressInfo = {
        first_name: this.address.firstName,
        last_name: this.address.lastName,
        address_line_1: this.address.addressLine1,
        address_line_2: this.address.addressLine2,
        address_line_3: this.address.addressLine3,
        phone: this.address.phone,
        city: this.address.city,
        state_or_province: this.address.stateOrProvince,
        postal_code: this.address.postalCode,
        country: this.address.country,
      };
      /* eslint-enable @typescript-eslint/camelcase */

      const data = {
        cart: this.cart,
        status: status,
        address: addressInfo,
      };

      api
        .checkout(data)
        .then(() => {
          this.loading = false;
          Message.success("Order Created!");
          this.$store.dispatch("Cart/deleteCart");
          this.$store.dispatch("Auth/UserStats");
          this.$store.dispatch("Product/loadProducts", {
            page: 1,
            itemsPerPage: 8,
          });
          this.$router.push({ name: "CheckoutComplete" });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (error.status === 400) {
            Message.info(error.response.data);
          } else if (error.status === 401) {
            Message.error(error.response.data);
          }
        });
    },
  },

  created() {
    this.$store.commit("Nav/SET_PAGE", "cart");
    this.$store.dispatch("Cart/setCart");
  },
};
</script>
<template>
  <div class="main-container">
    <div class="checkout-zone" v-show="cartCount > 0">
      <div class="header-area">
        <h1 class="">
          Checkout Details
        </h1>
        <!-- <div class="shipping-btn-group">
          <a class="blue-button " @click="loadAddressDetails">
            Use Details From My Last Order
            <i class="fas fa-map-marker-alt icon"></i>
          </a>
        </div> -->
      </div>
      <div class="zone-card">
        <shipping-address @addressChange="addressChange" />
        <div class="shipping-btn-group">
          <a class="dark-button mt-3" @click="checkout">
            Checkout
            <i class="fas fa-shopping-cart icon"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="cart-zone">
      <div class="header">
        <h1 class="title">
          Order Summary
        </h1>
        <!-- <a href="#" class="">Clear Cart</a> -->
      </div>
      <div class="zone-card">
        <div class="cart-items-list">
          <template v-if="cart.length > 0">
            <cart-item
              v-for="item in cart"
              :key="item.product.id"
              :cart="item"
            ></cart-item>
          </template>
          <template v-else>
            <a-empty description="Cart is Empty" class="pt-3" />
          </template>
        </div>
        <div class="cart-details">
          <div class="cart-details__points">
            <h1 class="cart-details__points--label">Points:</h1>
            <h2 class="cart-details__points--points">{{ cartPoints }}</h2>
          </div>
          <div class="cart-details__count">
            <h1 class="cart-details__count--label">Count</h1>
            <h2 class="cart-details__count--points">{{ cartCount }}</h2>
          </div>
          <div class="cart-details__total">
            <h1 class="cart-details__total--label">Total Amount</h1>
            <h2 class="cart-details__total--points">{{ cartPoints }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Rubik+Mono+One&family=Rubik:ital,wght@0,400;0,500;0,700;0,900;1,400&family=Source+Sans+Pro:wght@200;300;400;600;700&family=Yanone+Kaffeesatz:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Coda+Caption:wght@800&family=Modak&family=Permanent+Marker&display=swap");
@import "../styles/_mixins.scss";
@import "../assets/styles/_components.scss";
.header-area {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin-bottom: 3em;
}
.redeem-page {
  position: relative;
  top: 6.2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.my-container {
  margin: 0 9rem;
  position: relative;
}
.icon {
  margin-left: 1rem;
  // color: var(--color-teal);
}
.redeem-body {
  // margin: 5.7rem 9rem 0 9rem;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  // align-items: center;

  .item-list {
    // flex: 0 0 60rem;
    // margin-top: -3rem;
    font-family: "Source Sans Pro";
    margin-right: 6.1rem;
    align-self: center;
    width: 85rem;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title {
      // margin: 1.2em 0;
      @include respond(tab-p) {
      }
      @include respond(tab-l) {
      }
      @include respond(phone) {
      }
    }
    &__item {
      background: var();
      padding-bottom: 1.3em;
      margin-bottom: 5.3rem;
      border-bottom: var(--line);
      display: flex;
      align-items: center;
      &__thumb {
        //   margin-right: 1.3em;
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
      }
      &__title {
        font-size: 1.5em;
        margin: 0 8.5rem 0 5.5rem;
      }
      &__button-group {
        margin-right: 4em;
        @include respond(tab-p) {
        }
        @include respond(tab-l) {
        }
        @include respond(phone) {
          width: 3.1rem;
        }
      }
      &__points {
        margin-right: 4em;
        font-size: 1.4em;
        font-weight: 600;
        color: #000;
      }
      &__cancel {
      }
    }
    @include respond(tab-p) {
      width: 72rem;
    }
    @include respond(tab-l) {
      width: 98rem;
    }
    @include respond(phone) {
      width: 21rem;
    }
  }
  .checkout {
    // flex: 0 0 50rem;
    background: var(--color-grey-light-1);
    margin-top: 3rem;
    border: 0.6px solid var(--color-grey-light-4);
    border-radius: 1rem;
    // width: 50rem;
    background: var(--white);
    padding: 1.4em;
    box-shadow: var(--small-shadow-light-darker);
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    &__title {
      margin: 1.2em 0;
      font-family: "Source Sans Pro", "Segoe UI", Tahoma, Geneva, Verdana,
        sans-serif;
      color: var(--color-primary-dark);
    }
    .row {
      display: flex;
      justify-content: space-between;
      margin: 0.3rem;
      .items_count {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 1.9rem;
      }
      .items_points {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 1.9rem;
      }
    }
    .current-shipping-address {
      margin: 1.3rem 0.2rem;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 1.9rem;
    }
    .shipping-btn-group {
      display: flex;
      justify-content: center;
      align-items: center;
      .checkout-btn {
        float: right;
        background: var(--color-primary-dark);
        border: none;
        color: var(--color-white);
        margin-top: 1.6em;
        font-size: 1.5rem;
        width: 19rem;
        transition: 0.1ms ease-in;
        .btn-icon {
          margin-top: -1.7rem;
        }
        &:hover {
          box-shadow: var(--shadow-dark);
          height: 3.3rem;
          width: 20rem;
        }
      }
      .save-address-link {
        margin-top: 1.8rem;
      }
    }
    .name {
      font-family: var(--source-sans);
      font-size: 1.9rem;
      margin: 1.3rem 0.2rem;
    }
    .name-input {
      max-width: 30rem;
    }
    .line {
      border: 0.02rem solid var(--color-grey-light-3);
      margin-top: -1.2rem;
      margin-bottom: 1.2rem;
    }
  }
  @include respond(tab-p) {
  }
  @include respond(tab-l) {
  }
  @include respond(phone) {
  }
}
.main-container {
  font-family: var(--open-sans);
  // height: 100vh;
  padding-top: 9rem;
  background: var(--color-grey-light-1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  .checkout-zone {
    order: 1;

    .zone-card {
      padding: 3.2rem;
      width: 68rem;
      @include respond(phone) {
        align-self: center;
        width: 36rem;
      }
    }
    .title {
    }
    @include respond(phone) {
      // width: 2.3rem;
      margin-top: 4.2rem;
      order: 2;
    }
  }
  .cart-zone {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .zone-card {
      @include respond(phone) {
        width: 36rem;
      }
    }
  }
  @include respond(phone) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.title {
  margin-bottom: 0.8em;
}
.zone-card {
  // width: 45rem;
  // height: 30rem;
  border-radius: 0.7em;
  background: var(--white);
  box-shadow: var(--material-shadow-2);
  order: 1;
  .cart-items-list {
    max-height: 28rem;
    overflow-y: scroll;
  }
  .cart-details {
    display: flex;
    flex-direction: column;
    padding: 1.2em 2.3em;
    &__points {
      margin-bottom: 1em;
      display: flex;
      justify-content: space-between;
      &--label {
        color: var(--color-grey-dark-2);
        font-size: 1.7em;
        font-weight: 500;
      }
      &--points {
        font-size: 1.6em;
        color: var(--color-grey-dark-2);
      }
    }
    &__count {
      margin-bottom: 1em;
      display: flex;
      justify-content: space-between;
      &--label {
        color: var(--color-grey-dark-3);
        font-size: 1.7em;
        font-weight: 500;
      }
      &--points {
        font-size: 1.6em;
        color: var(--color-grey-dark-2);
      }
    }
    &__total {
      margin-bottom: 1em;
      display: flex;
      justify-content: space-between;
      // width: 2em;
      &--label {
        font-size: 1.8em;
        font-weight: 600;
      }
      &--points {
        font-size: 1.7em;
        color: var(--color-teal);
      }
    }
  }
  @include respond(phone) {
    order: 2;
  }
}

.cart-zone {
  order: 2;
  @include respond(phone) {
    order: 1;
  }
}
</style>
