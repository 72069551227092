<script>
import Vue from "vue";
import logoImg from "@/assets/new_logo.png";

export default {
  name: "CheckoutComplete",
  data() {
    return {
      logo: logoImg,
    };
  },
  created() {
    this.$store.commit("Nav/SET_PAGE", "checkout_complete");
  },
};
</script>
<template>
  <div class="first-time-page">
    <!-- <div class="row justify-content-center"> -->
    <div class="welcome-text" id="text">
      <p>
        We're delighted you found a reward you like! We'll have it on its way
        shortly. Please expect within about 3-6 weeks.<br />
      </p>
    </div>

    <router-link class="login-step-btn mt-3" :to="{ name: 'Home' }"
      >Back To Rewards!</router-link
    >
    <!-- <router-link :to="{ name: 'Home' }"> -->
    <img :src="logo" alt class="img" />
    <!-- </router-link> -->
  </div>
</template>
<style lang="scss" scoped>
@import "../assets/styles/_components.scss";
@import "../styles/_mixins.scss";
@import "../assets/styles/_utilities.scss";
.img {
  height: 13rem;
  width: 13rem;
  margin-top: 4em;
  transition: all 0.2s ease;
  @include respond(phone) {
    height: 9rem;
    width: 9rem;
  }
}
.first-time-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .welcome-text {
    white-space: pre-line;
    margin-top: 13.1rem;
    align-items: center;
    font-family: var(--baloo);
    font-size: 2.2rem;
    line-height: 3.3;
    text-align: center;
    width: 89rem;
    @include respond(tab-p) {
    }
    @include respond(tab-l) {
    }
    @include respond(phone) {
      font-size: 1.9rem;
      width: 24rem;
    }
  }
}
</style>
