<script>
import AInputNumber from "ant-design-vue/lib/input-number";
import AAvatar from "ant-design-vue/lib/avatar";
import Message from "ant-design-vue/lib/message";
export default {
  props: ["cart"],
  components: {
    AInputNumber,
    AAvatar,
  },
  data() {
    return {};
  },
  methods: {
    removeItemFromRedeems(product) {
      this.$store.dispatch("Cart/remove", product);
      Message.info(`Item removed from cart`);
    },
  },
};
</script>
<template>
  <div class="cart-item" :class="{ isAvailable: !cart.is_available }">
    <!-- <template></template> -->
    <div class="cart-item__description">
      <div class="cart-item__img">
        <template v-if="cart.product.main_image != null">
          <a-avatar shape="square" class="img" :src="cart.product.main_image" />
        </template>
        <template v-else>
          <a-avatar shape="square" :size="96">
            <i class="fas fa-file-image"></i>
          </a-avatar>
        </template>
      </div>
      <div class="cart-item__description--zone">
        <div class="cart-item__description--zone__title">
          <h1>{{ cart.product.title }}</h1>
        </div>
        <div class="cart-item__description--zone__points">
          <h2>Points: {{ cart.product.points }}</h2>
        </div>
        <div class="cart-item__description--zone__quantity">
          <a-input-number
            id="inputNumber"
            v-model="cart.quantity"
            class="number"
            :min="1"
            :max="cart.product.quantity"
          />
          <template v-if="!cart.is_available">
            <p class="warning">
              <i class="fas fa-info icon-left"></i>This item is no longer
              available for the indicated quantity
            </p>
          </template>
        </div>
      </div>
    </div>
    <div class="cart-item__action">
      <a
        class="cancel-button"
        @click.prevent="removeItemFromRedeems(cart.product.id)"
        ><i class="far fa-trash-alt icon trash-icon"></i
      ></a>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../styles/_mixins.scss";
@import "@/assets/styles/_utilities";
@import "@/assets/styles/_formTools";
@import "@/assets/styles/_components.scss";
.warning {
  color: var(--color-purple);
}
.cart-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 2.1rem 3.2rem;
  justify-content: space-between;
  &__img {
    .img {
      width: 9.6rem;
      height: 9.6rem;
      @include respond(phone) {
        width: 7.5rem;
        height: 7.5rem;
      }
    }
  }

  &__description {
    // height: 15rem;
    padding-right: 1.4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &--zone {
      padding-left: 1.5em;
      width: 20rem;
      &__title {
        h1 {
          font-size: 1.6em;
          font-weight: 600;
        }
      }
      &__points {
        h2 {
          color: var(--color-grey-dark-2);
          font-size: 1.5em;
        }
      }
      @include respond(phone) {
        width: 17rem;
      }
    }
  }
  @include respond(phone) {
    width: 1.2rem;
  }
}
.number {
  width: 6.4rem;
  height: 3.8rem;
}
.isAvailable {
  h1,
  h2 {
    color: var(--color-grey-light-4);
  }
}
p {
  margin-top: 1em;
  color: var(--lapis-blue);
}
</style>
