<script>
import Vue from "vue";
import vueCountryRegionSelect from "vue-country-region-select";
import { countryFunctions } from "@/services/country-helper";
import AInput from "ant-design-vue/lib/input";
import AFormModel from "ant-design-vue/lib/form-model";
import AFormModelItem from "ant-design-vue/lib/form-model/FormItem";
import ACol from "ant-design-vue/lib/grid/Col";
import ARow from "ant-design-vue/lib/grid/Row";
import ASelect from "ant-design-vue/lib/select";
import SelectOption from "ant-design-vue/lib/select";
// import AEmpty from "ant-design-vue/lib/empty"
// import CVA from 'vue'
Vue.use(vueCountryRegionSelect);
export default {
  components: {
    ACol,
    ARow,
    ASelect,
    SelectOption,
    AFormModel,
    AFormModelItem,
    AInput,
  },
  data() {
    return {
      address: {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        phone: "",
        city: "",
        stateOrProvince: "",
        postalCode: "",
        country: "",
      },
      countries: countryFunctions.getCountryNames(),
      region: "",
    };
  },
  watch: {
    address: {
      handler() {
        this.emit();
      },
      deep: true,
    },
  },
  methods: {
    loadAddressDetails() {
      const lastShippingAddress = this.$store.state.Auth.lastShippingAddress;

      const translator = {
        firstName: "first_name",
        lastName: "last_name",
        addressLine1: "address_line_1",
        addressLine2: "address_line_2",
        addressLine3: "address_line_3",
        phone: "phone",
        city: "city",
        stateOrProvince: "state_or_province",
        postalCode: "postal_code",
        country: "country",
      };

      if (lastShippingAddress) {
        for (const field in this.address) {
          const newField = translator[field];
          const value = lastShippingAddress[newField];
          this.address[field] = value;
        }
      }
    },
    emit() {
      this.$emit("addressChange", this.address);
    },
    selectCountry(country) {
      this.address.country = country;
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  // created() {
  //   const lastShippingAddress = this.$store.state.Auth.lastShippingAddress;

  //   const translator = {
  //     firstName: "first_name",
  //     lastName: "last_name",
  //     addressLine1: "address_line_1",
  //     addressLine2: "address_line_2",
  //     addressLine3: "address_line_3",
  //     phone: "phone",
  //     city: "city",
  //     stateOrProvince: "state_or_province",
  //     postalCode: "postal_code",
  //     country: "country",
  //   };

  //   if (lastShippingAddress) {
  //     for (const field in this.address) {
  //       const newField = translator[field];
  //       const value = lastShippingAddress[newField];
  //       this.address[field] = value;
  //     }
  //   }
  //   this.emit();
  // },
};
</script>

<template>
  <div>
    <a-form-model>
      <a-row>
        <div class="shipping-btn-group mb-3">
          <a class="blue-button " @click="loadAddressDetails">
            Use Details From My Last Order
            <i class="fas fa-map-marker-alt icon"></i>
          </a></div
      ></a-row>
      <a-row :gutter="48">
        <a-col :lg="{ span: 9 }" :xs="{ span: 24 }">
          <a-form-model-item label="First Name" required>
            <a-input
              name="firstName"
              v-model="address.firstName"
              required
              placeholder="First Name"
              v-decorator="[
                'name',
                {
                  rules: [
                    { required: true, message: 'Please include this field' },
                  ],
                },
              ]"
            />
          </a-form-model-item>
        </a-col>
        <a-col :lg="{ span: 9 }" :xs="{ span: 24 }">
          <a-form-model-item label="Last Name" required>
            <a-input
              name="lastName"
              v-model="address.lastName"
              required
              placeholder="Last Name"
            />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="10" :xs="{ span: 18 }">
          <a-form-model-item label="Phone">
            <a-input
              class="name-input"
              name="phone"
              v-model="address.phone"
              placeholder="Phone"
            />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-model-item label="Address Line 1">
            <a-input
              class="name-input"
              name="addressLine1"
              v-model="address.addressLine1"
              placeholder="Address Line 1"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="Address Line 2">
            <a-input
              class="name-input"
              name="addressLine2"
              v-model="address.addressLine2"
              placeholder="Address Line 2"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="16"> </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-model-item label="Address Line 3">
            <a-input
              class="name-input"
              name="addressLine3"
              v-model="address.addressLine3"
              placeholder="Address Line 3"
            />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :lg="{ span: 10 }" :xs="{ span: 24 }">
          <a-form-model-item label="City" required>
            <a-input
              class="name-input"
              name="city"
              v-model="address.city"
              placeholder="City"
              required
            />
          </a-form-model-item>
        </a-col>
        <a-col :lg="{ span: 10 }" :xs="{ span: 24 }">
          <a-form-model-item label="State/Province">
            <a-input
              class="name-input"
              name="stateOrProvince"
              v-model="address.stateOrProvince"
              placeholder="Phone"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :lg="{ span: 10 }" :xs="{ span: 24 }">
          <a-form-model-item label="Postal Code">
            <a-input
              class="name-input"
              name="postalCode"
              v-model="address.postalCode"
              placeholder="Postal code"
              required
            />
          </a-form-model-item>
        </a-col>
        <a-col :lg="{ span: 10 }" :xs="{ span: 24 }">
          <a-form-model-item label="Country" required>
            <a-select
              show-search
              option-filter-prop="countries"
              placeholder="Select Country"
              :filter-option="filterOption"
              @change="selectCountry"
            >
              <select-option v-for="country in countries" :key="country">
                {{ country }}
              </select-option>
            </a-select>
            <!-- <a-select
              class="name-input"
              name="country"
              :value="address.country"
              :options="country"
              placeholder="Country"
              required
            /> -->
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Rubik+Mono+One&family=Rubik:ital,wght@0,400;0,500;0,700;0,900;1,400&family=Source+Sans+Pro:wght@200;300;400;600;700&family=Yanone+Kaffeesatz:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Coda+Caption:wght@800&family=Modak&family=Permanent+Marker&display=swap");
@import "../styles/_mixins.scss";
@import "../assets/styles/_components.scss";
.shipping-btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  .checkout-btn {
    float: right;
    background: var(--color-primary-dark);
    border: none;
    color: var(--color-white);
    margin-top: 1.6em;
    font-size: 1.5rem;
    width: 19rem;
    transition: 0.1ms ease-in;
    .btn-icon {
      margin-top: -1.7rem;
    }
    &:hover {
      box-shadow: var(--shadow-dark);
      height: 3.3rem;
      width: 20rem;
    }
  }
  .save-address-link {
    margin-top: 1.8rem;
  }
}
.name {
  font-family: var(--source-sans);
  font-size: 1.4rem;
  margin: 1.3rem 0.2rem;
}
.name-input {
  max-width: 30rem;
}
</style>
